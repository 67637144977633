import React from "react";
import MiniHeroSection from "./MiniHeroSection";

const InfoSection = () => {
  return (
    <div className="py-10 pl-20 pr-10 xl:m-auto ipad:pl-10 phone:pl-0 phone:w-full phone:pr-0 phone:pt-20">
      <div className="pb-10">
        <h6>
          <i>
            <b>Nice to meet you</b>
          </i>
        </h6>
        <h2 className="text-[48px]">
          <b>WELCOME TO...</b>
        </h2>
      </div>
      <div className="grid grid-rows-3 grid-col-1 gap-x-10 gap-y-8 ipad:flex ipad:flex-col">
        <div className="row-start-1 row-span-3 col-span-1 ipad:row-start-1 ipad:row-span-1 ipad:px-[20%] phone:px-2">
          <MiniHeroSection />
        </div>
        <div className="col-start-2 row-start-1 row-span-1 col-span-1 ipad:col-start-1 ipad:row-start-2">
          <div className="ml-4  pb-12 grid grid-rows-2 grid-flow-col gap-8 phone:gap-4 border-b-[1px] phone:flex phone:flex-col phone:w-full">
            <div className="flex flex-auto px-4 col-start-1 row-start-1 row-span-1 col-span-1 items-center phone:px-0">
              <img className="h-8 mr-[8px]" src="./assets/phone.png" alt="Phone" />
              <b>
                <i>
                  <a href="tel:+917768903393" target="_blank" rel="noreferrer">
                    +91 7768903393
                  </a>
                </i>
              </b>
            </div>
            <div className="flex flex-auto px-4 col-start-2 row-start-1 row-span-1 col-span-1 items-center phone:px-0">
              <img className="h-8 mr-[8px]" src="./assets/Profile.png" alt="Mail" />
              <b>
                <i>25 years</i>
              </b>
            </div>
            <div className="flex flex-auto px-4 col-start-1 row-start-2 row-span-1 col-span-1 items-center phone:px-0">
              <img className="h-8 mr-[8px]" src="./assets/mail.png" alt="Mail" />
              <b>
                <i>
                  <a
                    href="mailto:chavangorakh19999@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    chavangorakh19999@gmail.com
                  </a>
                </i>
              </b>
            </div>
            <div className="flex flex-auto px-4 col-start-2 row-start-2 row-span-1 col-span-1 items-center phone:px-0">
              <img className="h-8 mr-[8px]" src="./assets/Location.png" alt="Mail" />
              <b>
                <i>Pune, India</i>
              </b>
            </div>
          </div>
        </div>
        <div className="col-start-2 row-start-2 row-span-1 col-span-1 ipad:col-start-1 ipad:row-start-3">
          <div className="flex flex-auto phone:flex-col">
            <div className="ml-4 pl-8 w-1/2 phone:w-full phone:pl-0 phone:ml-0 phone:pb-10">
              <div className="flex flex-auto">
                <div className="bg-gradient-to-r from-[#B86ADF] via-[#FF6C63] to-[#FFB147] inline-block text-transparent bg-clip-text text-6xl">
                  <b>3+</b>
                </div>
                <h6 className="text-xl pl-2 w-[40%]">
                  <b>Years experience...</b>
                </h6>
              </div>
              <div className="pr-10 pt-6">
                <p>
                  Hello there! My name is Gorakh Chavan.I am a SDE with
                  speciality in MERN and I am very passionate about my work.
                </p>
              </div>
            </div>
            <div className="w-1/2 phone:w-full">
              <div className="flex flex-auto">
                <div className="bg-gradient-to-r from-[#B86ADF] via-[#FF6C63] to-[#FFB147] inline-block text-transparent bg-clip-text text-6xl">
                  <b>10+</b>
                </div>
                <h6 className="text-xl pl-2  w-1/2">
                  <b>Projects Worldwide...</b>
                </h6>
              </div>
              <div className="pr-10 pt-6">
                <p>
                  Spearheaded the development of a revolutionary e-commerce
                  platform, streamlining user experience and scalability for
                  users worldwide.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-4 pl-8 col-start-2 row-start-3 row-span-1 col-span-1 ipad:col-start-1 ipad:row-start-4 phone:m-0 phone:p-0">
          <div className="flex flex-auto phone:flex-col bg-primary text-white text-xl px-8 py-6">
            <img
              className="w-[15%] pr-2"
              src="./assets/Quote.png"
              alt="Quote"
            />
            <p>
              <i>
                "An experienced software developer is like a master craftsman, weaving intricate code into elegant solutions that stand the test of time."
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
