import React from 'react'

const NotFound = () => {
  return (
    <div className="flex flex-auto ipad:pt-3/5 w-full  phone:px-10 phone:overflow-x-scroll justify-center items-center">
      <h1 className='text-8xl'>404</h1>
    </div>
  )
}

export default NotFound
