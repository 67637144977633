import React from 'react'

const Works = () => {
  return (
    <div className="flex flex-auto ipad:pt-3/5 w-full  phone:px-10 phone:overflow-x-scroll">
      Works
    </div>
  )
}

export default Works
