import { SpeedInsights } from "@vercel/speed-insights/react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import About from "./pages/About";
import Hero from "./pages/Hero";
import Services from "./pages/Services";
import Works from "./pages/Works";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
function App() {
  return (
    <BrowserRouter scrollRestoration="auto">
      <div className="flex font-ibm-regular">
        <Sidebar />
        <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/works" element={<Works />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <SpeedInsights/>
    </BrowserRouter>
  );
}

export default App;
