import React from "react";
import InfoSection from "../components/InfoSection";
import ExperienceSection from "../components/ExperienceSection";
const About = () => {
  return (
    <div className="col-right-main flex flex-col ipad:pt-3/5 w-full  phone:px-10 phone:overflow-x-scroll">
      <InfoSection />
      <ExperienceSection />
    </div>
  );
};



export default About;
