import React from "react";

const MiniHeroSection = () => {
  return (
    <div className="">
      <div className="relative p-[50%] bg-[url('../public/assets/MiniHero.png')] bg-no-repeat bg-contain bg-center rounded-full">
        <img
          className="absolute  bottom-0 -right-1 -z-10"
          src="./assets/MiniHeroBack.png"
          alt="Heroback"
        />
        <img
          className="absolute  top-1/4 left-1 w-1/5 -z-10"
          src="./assets/MiniElement.png"
          alt="Heroback"
        />
        {/* <img
          className="absolute  bottom-4 -right-4 w-3/5 z-10"
          src="./assets/Sign.png"
          alt="Heroback"
        /> */}
      </div>
      <div className="justify-center items-center h-full py-4 bg-gradient-to-r from-[#FFB147] via-[#FF6C63] to-[#B86ADF] inline-block text-transparent bg-clip-text text-4xl text-center">
        <b>GORAKH CHAVAN</b>
      </div>
      <div className="text-2xl flex justify-center items-center h-full">
        <h6>
          <i>
            <b>SDE</b>
          </i>
          &nbsp; based in&nbsp;
          <i>
            <b>Pune</b>
          </i>
        </h6>
      </div>
      <div className="flex justify-center items-center h-full py-10">
        <button className="p-auto align-center">Download CV</button>
      </div>
    </div>
  );
};

export default MiniHeroSection;
