import React from "react";
import ExperienceItem from "./ExperienceItem";
const ExperienceSection = () => {
  return (
    <div className="flex flex-row ipad:flex-col pl-24 ipad:px-16 phone:px-2 mt-8 py-16 phone:py-12 w-[90%] phone:w-full bg-gradient-to-r from-[#B86ADF] via-[#FF6C63] to-[#FFB147] text-white">
      <div className="w-[50%] ipad:w-full ipad:mb-10">
        <div className="w-[80%]">
          <h6>
            <i>Experience</i>
          </h6>
          <h3 className="text-3xl py-6">
            <b>MY EXPERIENCE</b>
          </h3>
          <p>
          Experienced MERN stack developer with over 3 years of expertise in building robust ecommerce web applications. Proficient in MongoDB, Express.js, React.js, and Node.js, with a strong focus on creating seamless user experiences and scalable solutions.
          </p>
          <button className="bg-primary text-white px-4 py-2 rounded my-4">
            Download my resume
          </button>
        </div>
      </div>
      <div className="w-[40%] ipad:w-full">
        <ExperienceItem
          from={"Apr-2023"}
          to={"Present"}
          company={"Digialpha"}
          position={"Sr Software Engineer"}
        />
        <ExperienceItem
          from={"Jun-2022"}
          to={"Mar-2023"}
          company={"Aigen Tech"}
          position={"Sr Backend Engineer"}
        />
        <ExperienceItem
          from={"Oct-2021"}
          to={"Jun-2022"}
          company={"Aigen Tech"}
          position={"Jr Backend Engineer"}
        />
        <ExperienceItem
          from={"Feb-2021"}
          to={"Aug-2021"}
          company={"Conversion Pro LLP"}
          position={"Frontend Developer"}
        />
      </div>
    </div>
  );
};

export default ExperienceSection;
