import React from "react";
import Socials from "../components/Socials";

const Hero = () => {
  return (
    <div className="col-right-main flex flex-auto ipad:flex-col ipad:p-10 phone:p-0 font-ibm-regular w-full h-screen  phone:px-6 phone:overflow-x-scroll">
      <div className="relative ipad:w-fit  mx-36 ipad-pro:mx-10 ipad:my-10 ipad:ml-10 my-auto mr-0 phone:mt-6 phone:mx-auto ">
        <div className="flex-auto z-40 ">
          <h1
            className="text-6xl phone:text-[3rem] 2xl:text-8xl"
            style={{ wordBreak: "break-word" }}
          >
            MY NAME IS <b>GORAKH CHAVAN....</b>
          </h1>
          <h3 className="text-3xl my-4">
            <i>
              <b>SDE</b>
            </i>{" "}
            based in{" "}
            <i>
              <b>Pune</b>
            </i>
          </h3>
          <button className="bg-primary text-white px-4 py-2 rounded-sm my-8">
            <a
              href="https://linkedin.com/in/chavangorakh1999/"
              target="_blank"
              rel="noreferrer"
            >
              Let's talk with me
            </a>
          </button>
          <ul className="flex flex-auto desktop:flex-col space-x-2 desktop:space-x-0 desktop:space-y-2">
            <li className="flex flex-auto h-8 items-center">
              <img className="h-8" src="./assets/phone.png" alt="Phone" />
              <i>
                <a href="tel:+917768903393" target="_blank" rel="noreferrer">
                  +91 7768903393
                </a>
              </i>
            </li>
            <li className="flex flex-auto h-8 items-center">
              <img className="h-8" src="./assets/mail.png" alt="Mail" />
              <i>
                <a
                  href="mailto:chavangorakh19999@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  chavangorakh19999@gmail.com
                </a>
              </i>
            </li>
          </ul>
        </div>

        <svg
          className="absolute top-0 -left-16 -z-10"
          width="216"
          height="136"
          viewBox="0 0 216 136"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M215.637 -2.08725e-07L0 124.498C100.654 160.028 196.291 104.812 215.637 -2.08725e-07Z"
            fill="url(#paint0_linear_1_491)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1_491"
              x1="224.856"
              y1="44.0991"
              x2="37.2265"
              y2="150.04"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFB147" />
              <stop offset="0.520264" stopColor="#FF6C63" />
              <stop offset="1" stopColor="#B86ADF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="flex flex-auto w-5/6 p-auto items-center justify-center ipad:mx-auto">
        <div className="relative p-[50%] phone:w-5/6 bg-[url('../public/assets/Hero.png')] bg-no-repeat bg-contain bg-center">
          <img
            className="opacity-80 absolute top-12 right-0 z-10"
            src="./assets/HeroTop.png"
            alt="HeroFront"
          />
          {/* <img
            className="absolute object-fit h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 grayscale-[70%] "
            src="./assets/Hero.png"
            alt="Hero"
          /> */}
          <img
            className="absolute  top-10 right-0 -z-10"
            src="./assets/HeroBack.png"
            alt="Heroback"
          />
        </div>
      </div>
      <div className="flex flex-col self-center ipad:hidden">
        <Socials width={10} />
      </div>
    </div>
  );
};

export default Hero;
