import React from "react";
const LinkPreview = ({ title,image,url }) => {

  return(
    <a href={url}>
      <div className="h-full w-full flex flex-col border-2 border-[#E1E1E2] rounded-lg">
        {image && (
          <img
            className="flex-grow-0 h-[60%] w-full object-fit rounded-t-lg"
            src={image}
            alt="Link Preview"
          />
        )}
        <h3 className="overflow-hidden px-2">{title}</h3>
      </div>
    </a>
  );
};

export default LinkPreview;
