export const ShimmerCard = ({ className }) => {
  return (
    <div className={`${className} `}>
      <div className="h-full w-full flex flex-col border-2 border-[#E1E1E2] rounded-lg">
        <div className="flex-grow-0 h-[60%] animate-pulse bg-[#CDD5DF] w-full object-fit" />
        <div className="px-2">
          <p class="leading-relaxed mt-2 mb-1 w-full h-3 animate-pulse bg-[#E1E6EC]"></p>
          <p class="leading-relaxed mb-1 w-2/3 h-3 animate-pulse bg-[#E1E6EC]"></p>
          <p class="leading-relaxed mb-1 w-1/2 h-3 animate-pulse bg-[#E1E6EC]"></p>
        </div>
      </div>
    </div>
  );
};
