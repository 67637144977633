import React, { useState } from "react";
import Socials from "./Socials";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

const Sidebar = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return <>{isMobile ? <MobileNav /> : <DesktopNav />}</>;
};

const DesktopNav = () => {
  const lineStyle = { textDecoration: "line-through", color: "red" };
  return (
    <div className="col-left-main bg-primary w-fit p-auto phone:hidden ">
      <div className="flex flex-col h-full place-content-between">
        <div>
        <NavLink to="/">
          <h1 className="px-14 pt-10 mb-10 text-white text-2xl ipad:text-3xl font-bold">
            GRC.
          </h1>
        </NavLink>
        <ul className=" px-14 mb-16 text-white space-y-3 2xl:space-y-6 ipad:space-y-8 font-ibm-regular text-xl ipad:text-2xl">
          <li>
            <NavLink
              to="/"
              style={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? lineStyle : {}
              }
            >
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              style={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? lineStyle : {}
              }
            >
              ABOUT
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              style={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? lineStyle : {}
              }
            >
              SERVICES
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/works"
              style={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? lineStyle : {}
              }
            >
              WORKS
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/blogs"
              style={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? lineStyle : {}
              }
            >
              BLOGS
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/contact"
              style={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? lineStyle : {}
              }
            >
              CONTACT
            </NavLink>
          </li> */}
        </ul>
        </div>    
        <div>
        <div className="flex justify-end-align-end pl-12 pt-10 mb-0">
          <Socials />
        </div>
        <span className="flex text-white text-xs pl-14 pr-14 py-6">
          Copyright ©2024 Gorakh Chavan. All right reserved.
        </span>
        </div> 
      </div>
    </div>
  );
};

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };
  const lineStyle = { textDecoration: "line-through", color: "red" };
  return (
    <>
      <div className="fixed top-0 right-0 w-full bg-primary z-10">
        <div className="flex justify-between text-white ">
        <NavLink to="/">
          <h1 className="m-4 text-white text-2xl font-bold">
            GRC.
          </h1>
        </NavLink>
          <div className="flex-grow"></div>
          {!isOpen && (
            <FontAwesomeIcon
              icon={faBars}
              onClick={toggleMenu}
              className="text-white text-2xl cursor-pointer m-4"
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-screen h-screen text-white"
          style={{ backgroundColor: "black", zIndex: 20 }}
        >
          <div className="flex flex-col justify-center items-center h-full">
            {/* Mobile menu items */}
            <ul className=" px-14 mb-16 text-white space-y-3 2xl:space-y-6 ipad:space-y-8 font-ibm-regular text-xl ipad:text-2xl">
              <li>
                <NavLink
                  to="/"
                  onClick={closeMenu}
                  style={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? lineStyle : {}
                  }
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  onClick={closeMenu}
                  style={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? lineStyle : {}
                  }
                >
                  ABOUT
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  onClick={closeMenu}
                  style={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? lineStyle : {}
                  }
                >
                  SERVICES
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/works"
                  onClick={closeMenu}
                  style={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? lineStyle : {}
                  }
                >
                  WORKS
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/blogs"
                  onClick={closeMenu}
                  style={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? lineStyle : {}
                  }
                >
                  BLOGS
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/contact"
                  onClick={closeMenu}
                  style={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? lineStyle : {}
                  }
                >
                  CONTACT
                </NavLink>
              </li> */}
            </ul>
          </div>
          {isOpen && (
            <div className="fixed top-0 right-0 m-4">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={toggleMenu}
                className="text-white text-2xl cursor-pointer m-4"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Sidebar;
