import React, { useState, useEffect } from "react";
import { fetchPosts } from "../api/posts";
import LinkPreview from "../components/LinkPreview";
import { ShimmerCard } from "../components/Shimmer";

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPosts()
      .then((response) => {
        setPosts(response ?? []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });
  }, []);
  return (
    <div className="col-right-main flex flex-auto ipad:pt-3/5 w-full  phone:px-10 phone:overflow-x-scroll">
      {loading ? (
        <div className=" mx-24 my-12 h-full w-full ipad:m-12 phone:m-4">
          <div className="h-[50%] flex flex-wrap place-content-around">
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
            <ShimmerCard className="h-full px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"/>
          </div>
        </div>
      ) : (
        <div className="mx-24 my-6 ipad:m-12 phone:m-4">
          <div className="flex flex-wrap place-content-around">
            {posts?.map((item) => {
              return (
                <div
                  key={item.url}
                  className="min-h-[60%] px-8 py-4 w-[30%] ipad:w-full ipad:py-8 ipad:px-4 phone:px-0"
                >
                  <LinkPreview
                    title={item.title}
                    image={item.image}
                    url={item.url}
                  />
                </div>
              );
            })}
          </div>
          <div className="w-full py-16 flex items-center justify-center">
            <div className="bg-primary text-white text-l px-6 py-2 rounded-sm">
              <a href="https://medium.com/@grcsr">Learn More</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
