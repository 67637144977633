import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
const SpecialityElement = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="py-10 border-b-[.2px]">
      <div
        className={`flex flex-auto  ${
          !isOpen ? "h-12 ipad:h-24" : "h-auto"
        } ipad:flex ipad:flex-col ipad:relative`}
      >
        <div className="w-[40%] text-xl ipad:w-[80%]">
          <h3
            className={`${
              isOpen
                ? "bg-gradient-to-r from-[#B86ADF] via-[#FF6C63] to-[#FFB147] inline-block text-transparent bg-clip-text"
                : ""
            }`}
          >
            <b>{title.toUpperCase()}</b>
          </h3>
        </div>
        <div className={`w-[45%] overflow-hidden ipad:w-[80%] min-h-[50px]`}>
          <p>{description}</p>
        </div>
        <div className="w-[15%] text-2xl font-thin flex justify-center items-start ipad:absolute ipad:top-0 ipad:right-0">
          <button className="pt-2" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <FontAwesomeIcon icon={faMinus} />
            ) : (
              <FontAwesomeIcon icon={faPlus} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpecialityElement;
