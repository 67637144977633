import React from "react";
import SpecialityElement from "../components/SpecialityElement";
import { ServiceListing } from "../utils/Services";

const Services = () => {
  return (
    <div className="col-right-main flex flex-auto ipad:pt-3/5 w-full  phone:px-10 phone:overflow-x-scroll">
      <div className="mx-24 my-12 ipad:m-12 phone:m-4">
        <h5>
          <i>Service</i>
        </h5>
        <h2 className="text-4xl">
          <b>MY SPECIALITIES</b>
        </h2>
        {ServiceListing.map((serviceElement) => {
          return (
            <SpecialityElement
              key={serviceElement.title}
              title={serviceElement.title}
              description={serviceElement.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Services;
