import React from "react";

const ExperienceItem = ({from,to,company,position}) => {
  return (
    <div className="mb-4 py-2 border-b-[0.1px]">
      <div className="flex justify-between text-s">
        <h6 className="text-left">{from} - {to} </h6>
        <a className="text-right" href="/">
          <h6>{company}</h6>
        </a>
      </div>
      <h4 className="text-2xl phon:text-xl font-bold py-4">{position}</h4>
    </div>
  );
};

export default ExperienceItem;
