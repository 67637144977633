export const ServiceListing = [
  {
    title: "Frontend Development",
    description:
      "I offer top-notch frontend development services tailored to your As a software developer specializing in frontend development, I offer expertise in creating user-friendly and visually appealing interfaces for web applications. With a strong command of HTML, CSS, and JavaScript, I ensure seamless integration of design elements and functionality, resulting in an engaging user experience. I am adept at utilizing modern frontend frameworks like React, Vue.js, and Angular to build dynamic and responsive web pages. My attention to detail, problem-solving skills, and ability to collaborate with designers and backend developers enable me to deliver high-quality frontend solutions that meet client requirements.needs. Our team of skilled developers specializes in creating responsive and user-friendly interfaces using the latest technologies such as HTML5, CSS3, JavaScript, and popular frameworks like React, Vue.js, and Angular. From designing intuitive layouts to optimizing performance, we ensure a seamless user experience across devices. Let us bring your vision to life with stunning, functional frontend solutions that enhance your brand and engage your audience.",
  },
  {
    title: "Backend Development",
    description:
      "As a software developer specializing in backend development, I provide comprehensive services to ensure the robustness, scalability, and efficiency of your applications. With expertise in languages like C++, Python,  I design and implement server-side logic, database integration, and API development, ensuring seamless communication between front-end and back-end systems. I focus on optimizing performance, security, and data management, leveraging frameworks like Django, Flask, and Spring Boot. Whether it's building RESTful APIs, handling authentication and authorization, or optimizing database queries, I deliver solutions tailored to your business needs. My commitment to clean code, testing, and continuous integration ensures reliable and maintainable software solutions that empower your business to thrive in a digital landscape.",
  },
  {
    title: "Full Stack Development",
    description: `As a Full Stack Developer, I provide comprehensive Fullstack development services encompassing both frontend and backend development. I excel in creating dynamic and responsive web applications using a wide range of technologies such as HTML, CSS, JavaScript, React.js, Angular, Node.js, Express.js, MongoDB, MySQL, and more.
        My expertise lies in designing user-friendly interfaces, implementing interactive features, optimizing website performance, and ensuring seamless integration between different components. I also have experience in version control systems like Git and deployment platforms such as AWS.
        With a strong focus on delivering high-quality solutions, I collaborate closely with clients to understand their requirements, provide technical insights, and deliver projects within specified timelines. My goal is to leverage my Fullstack development skills to create scalable, robust, and innovative web solutions that meet and exceed client expectations.`,
  },
  {
    title: "System Architecture",
    description:
      "As a Full Stack Developer specializing in System Architecture, I offer comprehensive solutions to optimize your software infrastructure. I bring expertise in designing scalable, reliable, and secure systems that meet your business objectives. My services include analyzing existing systems, identifying areas for improvement, and implementing efficient solutions using the latest technologies. Whether it's designing microservices architecture, optimizing database performance, or ensuring seamless integration between front-end and back-end components, I provide end-to-end support to enhance your system's performance, flexibility, and maintainability. Partner with me to elevate your software infrastructure and drive business success.",
  },
];
