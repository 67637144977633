import React from 'react'

const Socials = () => {
  return (
    <div className="flex mr-10">
    <ul className=" space-y-6 w-8 m-auto mb-4">
      <li className='rounded-full overflow-hidden bg-white'>
        <img src="./assets/instagram.png" alt="Instagram" />
      </li>
      <li className='rounded-full overflow-hidden bg-white'>
        <img src="./assets/google.png" alt="Google" />
      </li>
      <li className='rounded-full overflow-hidden bg-white'>
        <img src="./assets/facebook.png" alt="Facebook" />
      </li>
    </ul>
  </div>
  )
}


export default Socials
